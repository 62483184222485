import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const ErrorMessage = ({
  message = null,
  setMessage = () => {},
  autoHide = false,
}) => {
  const [visible, setVisible] = useState(!!message);
  const alertRef = useRef(null);

  useEffect(() => {
    if (message) {
      setVisible(true);
      // Focus the alert when it becomes visible
      if (alertRef.current) {
        alertRef.current.focus();
      }

      // Only set timer if autoHide is true
      if (autoHide) {
        const timer = setTimeout(() => {
          setVisible(false);
          if (setMessage) {
            setMessage(null);
          }
        }, 5000);
        return () => clearTimeout(timer);
      }
    }
  }, [message, setMessage, autoHide]);

  // Add global interaction listeners
  useEffect(() => {
    if (!visible) return undefined;

    const handleGlobalInteraction = (event) => {
      // Ignore if clicking inside the alert
      if (alertRef.current?.contains(event.target)) {
        return;
      }

      // If user interacts outside the alert, dismiss it
      setVisible(false);
      if (setMessage) {
        setMessage(null);
      }
    };

    // Add listeners for both clicks and key presses
    document.addEventListener('click', handleGlobalInteraction);
    document.addEventListener('keydown', handleGlobalInteraction);

    // Cleanup listeners
    return () => {
      document.removeEventListener('click', handleGlobalInteraction);
      document.removeEventListener('keydown', handleGlobalInteraction);
    };
  }, [visible, setMessage]);

  const handleClose = () => {
    setVisible(false);
    if (setMessage) {
      setMessage(null);
    }
  };

  if (!visible || !message) {
    return null;
  }

  return (
    <Alert
      ref={alertRef}
      severity="error"
      icon={<ErrorOutlineIcon color="error" style={{ fontSize: 30 }} />}
      tabIndex={-1}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      onClick={(e) => e.stopPropagation()} // Prevent clicks on the alert from bubbling up
      sx={{
        '&:focus': {
          outline: 'none',
        },
        mb: 2,
        mx: 'auto',
        maxWidth: '100%',
        borderRadius: 2,
        boxShadow: 3,
        border: '1px solid',
        borderColor: 'error.main',
        backgroundColor: 'error.lighter',
        '& .MuiAlert-icon': {
          color: 'error.main',
        },
        '& .MuiAlert-message': {
          color: 'error.dark',
          fontWeight: 500,
        },
        '& .MuiAlert-action': {
          alignItems: 'center',
          paddingTop: 0,
        },
      }}
    >
      {message}
    </Alert>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func,
  autoHide: PropTypes.bool,
};

export default ErrorMessage;
