import { apiRequest } from '../api/api';

const templateService = {
  getAllTemplates: async () => {
    const response = await apiRequest('GET', '/template/list');
    return response;
  },

  getTemplateById: async (id) => {
    const response = await apiRequest('GET', `/template/${id}`);
    return response; // Return the full response to handle both template and sections
  },

  createTemplate: async (templateData) => {
    const response = await apiRequest('POST', '/template', {
      data: templateData,
    });
    return response;
  },

  updateTemplate: async (id, templateData, templateSections) => {
    const response = await apiRequest('PUT', `/template/${id}/sections`, {
      data: {
        ...templateData,
        templateSections,
      },
    });
    return response;
  },

  deleteTemplate: async (id) => {
    const response = await apiRequest('DELETE', `/template/${id}`);
    return response;
  },

  cloneTemplate: async (id) => {
    const response = await apiRequest('POST', `/template/${id}/clone`);
    return response;
  },

  exportTemplate: async (id) => {
    const response = await apiRequest('GET', `/template/${id}/export`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/json',
      },
    });
    return response;
  },

  importTemplate: async (jsonData) => {
    try {
      // Required fields validation
      const requiredFields = ['name', 'templateSections'];
      for (const field of requiredFields) {
        if (!jsonData[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }

      // Validate template sections
      if (!Array.isArray(jsonData.templateSections)) {
        throw new Error('Template sections must be an array');
      }

      // Clean up the imported data to match the expected format
      const cleanedData = {
        name: jsonData.name,
        headerTitle: jsonData.headerTitle || '',
        nurturerRole: jsonData.nurturerRole || '',
        flourisherRole: jsonData.flourisherRole || '',
        targetedAt: jsonData.targetedAt || 'nurturer',
        label: jsonData.label || '',
        labelShort: jsonData.labelShort || '',
        ctaTitle: jsonData.ctaTitle || '',
        ctaBody: jsonData.ctaBody || '',
        icon: jsonData.icon || '',
        disclaimerBody: jsonData.disclaimerBody || '',
        templateUrl: jsonData.templateUrl || '',
        systemPrompt: jsonData.systemPrompt || '',
        refinePrompt: jsonData.refinePrompt || '',
        format: jsonData.format || 'text',
        markdown: jsonData.markdown || false,
        public: jsonData.public || false,
        published: jsonData.published || false,
        active: jsonData.active !== undefined ? jsonData.active : true,
        themeID: jsonData.themeID || '',
        templateSections: jsonData.templateSections.map((section) => ({
          ...section,
          id: section.id || Date.now().toString(),
          order: section.order || 0,
        })),
      };

      return cleanedData;
    } catch (error) {
      throw new Error(error.message || 'Failed to parse template file');
    }
  },
};

export default templateService;
