import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../components/iconify';
import { renderWithClickableTimestamps } from '../utils/timestampUtils';

const TitleTypography = styled(Typography)(({ theme }) => ({
  variant: 'h3',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  marginBottom: '0.35em',
  wordBreak: 'break-word',
}));

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  variant: 'h4',
  color: theme.palette.text.secondary,
  marginBottom: '0.3em',
  wordBreak: 'break-word',
}));

const formatReportContentJSON = (content, onTimeClick) => (
  <Box>
    <Typography variant="body1">{content.description}</Typography>
    {content.sections.map((section, index) => (
      <Box key={index} mt={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="rgba(0, 0, 0, 0.04)"
        >
          <Typography variant="subtitle1">{`${index + 1}. ${section.sectionTitle}`}</Typography>
          {section.items.some(
            (item) => item.label.toLowerCase() === 'evidence'
          ) && (
            <Box display="flex" alignItems="center" ml={1}>
              {section.items.find(
                (item) => item.label.toLowerCase() === 'evidence'
              ).value === 'true' ? (
                <Iconify icon={'openmoji:check-mark'} />
              ) : (
                <Iconify icon={'openmoji:cross-mark'} />
              )}
            </Box>
          )}
        </Box>
        <Box mt={0}>
          <List sx={{ pt: 0 }}>
            {section.items.map((item, itemIndex) => (
              <ListItem key={itemIndex} sx={{ pt: 0 }}>
                <ListItemText
                  primary={
                    item.label.toLowerCase() !== 'evidence' && (
                      <Typography variant="body1">{`${item.label}: ${item.value}`}</Typography>
                    )
                  }
                  secondary={
                    <Typography variant="body2" component="div">
                      {typeof item.details === 'string'
                        ? renderWithClickableTimestamps(
                            item.details,
                            onTimeClick
                          )
                        : item.details}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    ))}
    {content.summary && (
      <Box mt={2}>
        <Typography variant="subtitle1">{content.summary.label}</Typography>
        <Typography variant="body1">{content.summary.value}</Typography>
      </Box>
    )}
    {content.additionalInfo.map((info, index) => (
      <Box key={index} mt={2}>
        <Typography variant="subtitle1">{info.label}</Typography>
        <Typography variant="body1">{info.value}</Typography>
      </Box>
    ))}
  </Box>
);

const formatReportContentWithMarkdown = (content, onTimeClick) => {
  // Strip out any markdown code block syntax and language identifiers
  content = content.replace(/````\w*\n/g, '').replace(/```/g, '');

  // New helper that preserves the element structure in markdown
  const processChildren = (children) =>
    React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return renderWithClickableTimestamps(child, onTimeClick);
      } else if (React.isValidElement(child)) {
        // If it's a <br>, keep it as is
        if (child.type === 'br') return child;
        // Recursively process its children
        return React.cloneElement(child, {
          children: processChildren(child.props.children),
        });
      }
      return child;
    });

  // Create components that handle timestamps within each markdown element
  const components = {
    p: ({ children }) => (
      <Typography
        variant="body2"
        color="text.secondary"
        component="div"
        sx={{ mb: 2 }}
      >
        {processChildren(children)}
      </Typography>
    ),
    li: ({ children }) => (
      <Typography
        variant="body2"
        color="text.secondary"
        component="li"
        sx={{
          whiteSpace: 'pre-line',
          display: 'flex',
          alignItems: 'flex-start',
          pl: 2,
          mb: 1.5,
          '&::before': {
            content: '"•"',
            marginRight: 1,
            flexShrink: 0,
          },
          '& > span': {
            display: 'inline-block',
            flex: 1,
          },
        }}
      >
        <span>{processChildren(children)}</span>
      </Typography>
    ),
    h1: ({ children }) => (
      <TitleTypography component="div" style={{ whiteSpace: 'pre-line' }}>
        {processChildren(children)}
      </TitleTypography>
    ),
    h2: ({ children }) => (
      <SubTitleTypography component="div" style={{ whiteSpace: 'pre-line' }}>
        {processChildren(children)}
      </SubTitleTypography>
    ),
    h3: ({ children }) => (
      <Typography
        variant="h5"
        color="text.secondary"
        component="div"
        style={{ whiteSpace: 'pre-line' }}
      >
        {processChildren(children)}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography
        variant="h6"
        color="text.secondary"
        component="div"
        style={{ whiteSpace: 'pre-line' }}
      >
        {processChildren(children)}
      </Typography>
    ),
    h5: ({ children }) => (
      <Typography
        variant="subtitle1"
        color="text.secondary"
        component="div"
        style={{ whiteSpace: 'pre-line' }}
      >
        {processChildren(children)}
      </Typography>
    ),
    h6: ({ children }) => (
      <Typography
        variant="subtitle2"
        color="text.secondary"
        component="div"
        style={{ whiteSpace: 'pre-line' }}
      >
        {processChildren(children)}
      </Typography>
    ),
    strong: ({ children }) => (
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {processChildren(children)}
      </Typography>
    ),
    em: ({ children }) => (
      <Typography component="span" sx={{ fontStyle: 'italic' }}>
        {processChildren(children)}
      </Typography>
    ),
    blockquote: ({ children }) => (
      <Box
        component="blockquote"
        sx={{
          borderLeft: (theme) => `4px solid ${theme.palette.divider}`,
          pl: 2,
          my: 2,
          color: 'text.secondary',
          whiteSpace: 'pre-line',
        }}
      >
        {processChildren(children)}
      </Box>
    ),
    code: ({ children }) => (
      <Typography
        component="code"
        sx={{
          backgroundColor: (theme) => theme.palette.action.hover,
          p: 0.5,
          borderRadius: 1,
          fontFamily: 'monospace',
          whiteSpace: 'pre-line',
        }}
      >
        {processChildren(children)}
      </Typography>
    ),
  };

  return (
    <Box sx={{ color: 'text.secondary' }}>
      <ReactMarkdown remarkPlugins={[remarkBreaks]} components={components}>
        {content}
      </ReactMarkdown>
    </Box>
  );
};

const formatReportContent = (content, report, onTimeClick) => {
  // If markdown is enabled, use pure markdown formatting
  if (report.templateMarkdown) {
    return formatReportContentWithMarkdown(content, onTimeClick);
  }

  // Original formatting logic for non-markdown mode
  // Strip out any markdown code block syntax and language identifiers
  content = content.replace(/```\w*\n/g, '').replace(/```/g, '');

  // Helper function to handle bold text
  const formatBoldText = (text) => {
    if (!text || typeof text !== 'string') return text;

    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        const boldText = part.slice(2, -2);
        return (
          <Typography
            key={`bold-${index}`}
            component="span"
            sx={{ fontWeight: 'bold' }}
          >
            {renderWithClickableTimestamps(boldText, onTimeClick)}
          </Typography>
        );
      }
      // For regular text, just return the text content with a key
      return (
        <span key={`regular-${index}`}>
          {renderWithClickableTimestamps(part, onTimeClick)}
        </span>
      );
    });
  };

  // Handle markdown-style content with segments
  if (content.includes('**') || content.includes('#')) {
    // Split content into sections based on double newlines, but filter out empty sections
    const segments = content
      .split('\n\n')
      .filter((segment) => segment.trim())
      .map((segment) => {
        // Check if this segment starts with a heading
        const lines = segment.split('\n');
        const firstLine = lines[0].trim();
        if (firstLine.startsWith('#')) {
          const headingLevel = firstLine.match(/^#+/)[0].length;
          const variant =
            headingLevel === 1
              ? 'h4'
              : headingLevel === 2
                ? 'h5'
                : headingLevel === 3
                  ? 'h6'
                  : headingLevel >= 4
                    ? 'h6'
                    : 'h6';
          return (
            <Box key={`segment-${firstLine}`}>
              <Typography
                variant={variant}
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {formatBoldText(firstLine.replace(/^#+\s*/, '').trim())}
              </Typography>
              {lines.length > 1 && (
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {lines.slice(1).map((item, index) => (
                    <li key={index}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {formatBoldText(
                          item
                            .trim()
                            .replace(/^[•-]\s*/, '')
                            .trim()
                        )}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          );
        }
        // Check if this segment contains bullet points (now checking for both '-' and '•')
        if (
          segment
            .split('\n')
            .some(
              (line) =>
                line.trim().startsWith('-') || line.trim().startsWith('•')
            )
        ) {
          const lines = segment.split('\n');
          // Get non-bullet-point lines as headers
          const headers = lines.filter(
            (line) =>
              !line.trim().startsWith('-') && !line.trim().startsWith('•')
          );
          // Get bullet points
          const items = lines.filter(
            (line) => line.trim().startsWith('-') || line.trim().startsWith('•')
          );

          return (
            <Box key={`segment-${headers[0] || 'list'}`}>
              {headers.map((header, idx) => (
                <Typography
                  key={idx}
                  variant="body2"
                  color="text.secondary"
                  component="div"
                  sx={{ mb: headers.length > 1 ? 1 : 0 }}
                >
                  {formatBoldText(header)}
                </Typography>
              ))}
              {items.length > 0 && (
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {items.map((item, index) => (
                    <li key={index}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {formatBoldText(
                          item
                            .trim()
                            .replace(/^[•-]\s*/, '')
                            .trim()
                        )}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          );
        }
        // Regular segment without bullet points
        return (
          <Typography
            key={`segment-text-${segment.slice(0, 20)}`}
            variant="body2"
            color="text.secondary"
            component="div"
          >
            {formatBoldText(segment)}
          </Typography>
        );
      });

    return (
      <Box>
        {segments.map((segment, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            {segment}
          </Box>
        ))}
      </Box>
    );
  }

  // If the content doesn't contain any bullet points, return it directly
  if (!content.split('\n').some((line) => line.trim().startsWith('-'))) {
    return (
      <Typography variant="body2" color="text.secondary">
        {renderWithClickableTimestamps(content, onTimeClick)}
      </Typography>
    );
  }

  // Original bullet point handling logic remains unchanged
  const sections = content.split('\n').filter((line) => line.trim() !== '');
  const formattedContent = [];
  let currentCategory = { title: '', items: [] };

  sections.forEach((section, index) => {
    if (!section.trim().startsWith('-')) {
      if (currentCategory.items.length > 0) {
        formattedContent.push(currentCategory);
      }
      let title = section.trim();
      // Remove heading markers and asterisks
      title = title
        .replace(/^#+\s*/, '')
        .replace(/[*]/g, '')
        .trim();

      const timeMatch = title.match(/\(([\d\w:]+\s*-\s*[\d\w:]+|[\d\w:]+)\)/);
      const titleWithoutTime = timeMatch
        ? title.replace(timeMatch[0], '').trim()
        : title;

      const hasTimestampColon = /\d+[:]\d+/.test(titleWithoutTime);

      const finalTitle = hasTimestampColon
        ? renderWithClickableTimestamps(titleWithoutTime, onTimeClick)
        : titleWithoutTime.split(':')[0];
      const subtitle = hasTimestampColon
        ? ''
        : titleWithoutTime.includes(':')
          ? renderWithClickableTimestamps(
              titleWithoutTime.split(':').slice(1).join(':').trim(),
              onTimeClick
            )
          : '';

      currentCategory = {
        title: finalTitle,
        subtitle: subtitle,
        timestamp: timeMatch ? timeMatch[1] : null,
        items: [],
      };
    } else {
      const itemContent = section.trim().substring(1).trim();
      currentCategory.items.push(itemContent);
    }
    if (index === sections.length - 1 && currentCategory.items.length > 0) {
      formattedContent.push(currentCategory);
    }
  });

  const listJSX = formattedContent.map((category, index) => (
    <Box key={index} sx={{ mb: 2 }}>
      <TitleTypography component="div">
        {typeof category.title === 'string'
          ? renderWithClickableTimestamps(category.title, onTimeClick)
          : category.title}
        {category.timestamp && (
          <Typography
            component="span"
            variant="body2"
            sx={{ ml: 1, color: 'text.secondary' }}
          >
            {renderWithClickableTimestamps(
              `(${category.timestamp})`,
              onTimeClick
            )}
          </Typography>
        )}
      </TitleTypography>
      {category.subtitle && (
        <SubTitleTypography>
          {typeof category.subtitle === 'string'
            ? renderWithClickableTimestamps(category.subtitle, onTimeClick)
            : category.subtitle}
        </SubTitleTypography>
      )}
      <ul style={{ margin: 0, paddingLeft: '20px' }}>
        {category.items.map((item, itemIndex) => (
          <li key={itemIndex}>
            <Typography variant="body2" color="text.secondary" component="div">
              {renderWithClickableTimestamps(item, onTimeClick)}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  ));

  return <div>{listJSX}</div>;
};

const reportService = {
  formatReportContent,
  formatReportContentJSON,
  formatReportContentWithMarkdown,
};

export default reportService;
