import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  Grid,
  Button,
  Box,
  Stack,
  Tooltip,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import Iconify from '../../components/iconify';
import GenericSnackbar from '../../components/snackbar/GenericSnackbar';
import templateService from '../../services/templateService';
import themeService from '../../services/themeService';
import ThemeFilter from '../../sections/@dashboard/templates/ThemeFilter';
import PromptDialog from '../../components/dialog/PromptDialog';

export default function TemplatesPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [templates, setTemplates] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [themes, setThemes] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (state?.snackbar) {
      setSnackbar(state.snackbar);
      navigate(location.pathname, { replace: true });
    }
  }, [state, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedTemplates, fetchedThemes] = await Promise.all([
          templateService.getAllTemplates(),
          themeService.getAllThemes(),
        ]);
        setTemplates(fetchedTemplates);
        setThemes(fetchedThemes);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbar({
          open: true,
          message: 'Failed to fetch data',
          severity: 'error',
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTheme) {
      setFilteredTemplates(
        templates.filter((template) => template.themeID === selectedTheme)
      );
    } else {
      setFilteredTemplates(templates);
    }
  }, [selectedTheme, templates]);

  const handleCreateTemplate = () => {
    navigate('/home/wundamental/templates/new');
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const handleTemplateClick = (template) => {
    navigate(`/home/wundamental/templates/${template._id}`);
  };

  const handleMenuOpen = (event, template) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(template);
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleCloneTemplate = async (event) => {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    try {
      await templateService.cloneTemplate(selectedTemplate._id);
      const fetchedTemplates = await templateService.getAllTemplates();
      setTemplates(fetchedTemplates);
      setSnackbar({
        open: true,
        message: 'Template cloned successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error cloning template:', error);
      setSnackbar({
        open: true,
        message: 'Failed to clone template',
        severity: 'error',
      });
    }
    setSelectedTemplate(null);
  };

  const handleExportTemplate = async (event) => {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    try {
      const response = await templateService.exportTemplate(
        selectedTemplate._id
      );

      // Create a blob URL and trigger download
      const blob = new Blob([response], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName = `template_${selectedTemplate.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${Date.now()}.json`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setSnackbar({
        open: true,
        message: 'Template exported successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error exporting template:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export template',
        severity: 'error',
      });
    }
    setSelectedTemplate(null);
  };

  const handleDeleteTemplate = async () => {
    try {
      await templateService.deleteTemplate(selectedTemplate._id);
      setOpenDeleteDialog(false);
      setSnackbar({
        open: true,
        message: 'Template deleted successfully',
        severity: 'success',
      });
      const fetchedTemplates = await templateService.getAllTemplates();
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error deleting template:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete template',
        severity: 'error',
      });
      setOpenDeleteDialog(false);
    }
    setSelectedTemplate(null);
  };

  const handleDeleteClick = (event) => {
    if (event) {
      event.stopPropagation();
    }
    handleMenuClose();
    setOpenDeleteDialog(true);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        px: { xs: 2, sm: 3 },
        mx: 'auto',
        width: '100%',
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretch', sm: 'center' }}
          justifyContent="space-between"
          spacing={2}
        >
          <Typography variant="h4">Templates</Typography>
          <Button
            fullWidth
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleCreateTemplate}
          >
            New Template
          </Button>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'flex-end',
            alignItems: { xs: 'stretch', sm: 'center' },
            gap: 1,
          }}
        >
          {selectedTheme && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: { xs: 'center', sm: 'left' } }}
            >
              Filtered by: {themes.find((t) => t._id === selectedTheme)?.name}
            </Typography>
          )}
          <ThemeFilter
            themes={themes}
            selectedTheme={selectedTheme}
            onThemeChange={setSelectedTheme}
          />
        </Box>

        <Grid
          container
          spacing={3}
          sx={{
            '& .MuiGrid-item': {
              padding: '10px !important',
            },
            margin: 0,
            width: '100%',
          }}
        >
          {filteredTemplates.map((template) => (
            <Grid key={template._id} item xs={12} sm={6} lg={4} sx={{ p: 0 }}>
              <Card
                sx={{
                  p: { xs: 2, sm: 3 },
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    boxShadow: (theme) => theme.shadows[10],
                  },
                }}
                onClick={() => handleTemplateClick(template)}
              >
                <Box sx={{ position: 'relative', flex: 1 }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: { xs: 4, sm: 8 },
                      right: { xs: 4, sm: 8 },
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Tooltip
                      title={
                        template.public ? 'Public template' : 'Private template'
                      }
                    >
                      <Iconify
                        icon={template.public ? 'mdi:earth' : 'mdi:lock'}
                        width={16}
                        height={16}
                        sx={{ color: 'text.secondary' }}
                      />
                    </Tooltip>
                    {template.published && (
                      <Tooltip title="Published">
                        <Iconify
                          icon="mdi:check-circle"
                          width={16}
                          height={16}
                          sx={{ color: 'success.main' }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Stack spacing={{ xs: 1, sm: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1,
                        pr: { xs: 3, sm: 4 },
                      }}
                    >
                      {template.icon && (
                        <Iconify
                          icon={template.icon}
                          width={24}
                          sx={{ flexShrink: 0 }}
                        />
                      )}
                      <Typography
                        variant="h6"
                        sx={{
                          wordBreak: 'break-word',
                          fontSize: { xs: '1rem', sm: '1.25rem' },
                        }}
                      >
                        {template.name}
                      </Typography>
                    </Box>
                    <Stack spacing={1}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          flexWrap: 'wrap',
                        }}
                      >
                        {template.themeName && (
                          <Chip
                            size="small"
                            label={template.themeName}
                            color="info"
                            icon={
                              <Iconify icon="mdi:theme-light-dark" width={16} />
                            }
                          />
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: { xs: 8, sm: 12 },
                    right: { xs: 8, sm: 12 },
                  }}
                >
                  <Tooltip title="Template actions">
                    <IconButton
                      size="small"
                      onClick={(e) => handleMenuOpen(e, template)}
                      sx={{ color: 'text.secondary' }}
                    >
                      <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleCloneTemplate}>
          <Iconify icon="eva:copy-fill" sx={{ mr: 2 }} />
          Clone
        </MenuItem>
        <MenuItem onClick={handleExportTemplate}>
          <Iconify icon="eva:download-fill" sx={{ mr: 2 }} />
          Export
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-fill" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Menu>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      />

      <PromptDialog
        open={openDeleteDialog}
        title="Delete Template"
        message="Are you sure you want to delete this template? This action cannot be undone."
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteTemplate}
        confirmButtonLabel="Delete"
      />
    </Container>
  );
}
