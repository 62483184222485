import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../iconify';

const WundaInlineEdit = ({
  value,
  onSave,
  disabled = false,
  multiline = false,
  variant = 'body2',
  color = 'text.secondary',
  className = '',
  children,
  sx = {},
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const isSavingRef = useRef(false);
  const savingViaButtonRef = useRef(false);

  const handleStartEdit = () => {
    if (disabled) return;
    setIsEditing(true);
    setEditValue(value);
  };

  const handleSave = async (skipBlur = false) => {
    // If we're already saving, don't start another save
    if (isSavingRef.current) return;

    if (skipBlur) {
      savingViaButtonRef.current = true;
    }

    // Skip update if content hasn't changed
    if (editValue === value) {
      setIsEditing(false);
      setEditValue('');
      savingViaButtonRef.current = false;
      return;
    }

    try {
      isSavingRef.current = true;
      await onSave(editValue);
    } finally {
      isSavingRef.current = false;
      setIsEditing(false);
      if (skipBlur) {
        setTimeout(() => {
          savingViaButtonRef.current = false;
        }, 100);
      }
    }
  };

  if (isEditing) {
    return (
      <Box sx={{ position: 'relative' }}>
        <TextField
          multiline={multiline}
          fullWidth
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onBlur={() => {
            if (!savingViaButtonRef.current) {
              handleSave();
            }
          }}
          onKeyDown={(e) => {
            if (
              multiline ? e.key === 'Enter' && e.ctrlKey : e.key === 'Enter'
            ) {
              handleSave(true);
            }
          }}
          variant="standard"
          autoFocus
          InputProps={{
            sx: {
              typography: variant,
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
              pl: 2,
              borderLeft: '2px solid',
              borderColor: 'divider',
              fontSize: sx.fontSize,
              lineHeight: sx.lineHeight,
              letterSpacing: sx.letterSpacing,
              '& .MuiInputBase-input': {
                p: 1,
                color: 'text.primary',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                letterSpacing: 'inherit',
              },
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              transition: theme.transitions.create(['background-color'], {
                duration: theme.transitions.duration.shortest,
              }),
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: '50%',
            p: 0.5,
            boxShadow: 1,
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'action.hover',
            },
            zIndex: 1,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSave(true);
          }}
        >
          <Iconify
            icon="eva:checkmark-outline"
            sx={{
              width: 16,
              height: 16,
              color: 'text.secondary',
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover .edit-icon': {
          opacity: disabled ? 0 : 1,
        },
      }}
    >
      <Typography
        variant={variant}
        color={color}
        component="div"
        className={className}
        sx={{
          cursor: disabled ? 'default' : 'text',
          p: 1,
          borderRadius: 1,
          '&:hover': {
            bgcolor: disabled ? 'transparent' : 'action.hover',
          },
          position: 'relative',
          whiteSpace: 'pre-wrap',
          ...sx,
        }}
        onClick={handleStartEdit}
      >
        {children || value}
      </Typography>
      {!disabled && (
        <Box
          className="edit-icon"
          sx={{
            position: 'absolute',
            top: multiline ? -10 : -15,
            right: multiline ? -10 : -24,
            opacity: 0,
            transition: theme.transitions.create('opacity', {
              duration: theme.transitions.duration.shortest,
            }),
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: '50%',
            p: 0.5,
            boxShadow: 1,
            zIndex: 1,
            '& .MuiSvgIcon-root': {
              width: 16,
              height: 16,
              color: 'text.secondary',
            },
            ...(sx['& .edit-icon'] || {}),
          }}
        >
          <Iconify
            icon="eva:edit-2-outline"
            sx={{
              width: 16,
              height: 16,
              color: 'text.secondary',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

WundaInlineEdit.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default WundaInlineEdit;
